import type { CountriesCode } from '@/layouts/main/Footer/constants';

type PathTalkAdvisor = Record<string, string>;

const path = (root: string, sublink: string) => `${root}${sublink}`;

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE99 = {
  product: {
    delivery: '/envios99',
    fulfill: '/fulfill99',
    cash: '/cash99',
    punto: '/punto99',
    ruta: '/ruta99',
    store: 'https://99minutos.store/',
    tailor: '/tailor99',
  },
  integrations: {
    meli: 'https://developers.99minutos.com/page/meli-flex',
    tienda: 'https://example-99mxn.readme.io/page/plugin-installation-tienda-nube',
    mag: 'https://example-99mxn.readme.io/page/plugin-installation-magento',
    bc: 'https://example-99mxn.readme.io/page/api-installation-bigcommerce',
    shop: 'https://developers.99minutos.com/page/shopify',
    ps: 'https://example-99mxn.readme.io/page/plugin-installation-prestashop',
    js: 'https://example-99mxn.readme.io/page/plugin-installation-jumpseller',
    api: 'https://developers.99minutos.com/',
    wc: 'https://developers.99minutos.com/page/plugin-installation-woocommerce',
  },
  about: {
    culture: '/culture',
    partnership: '/partnership',
    sustain: '/sustain',
    delivery: '/delivery',
  },
  rates: '/rates',
};

export const PATH_PAGE = {
  about: '/about-us',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  fileManager: path(ROOTS_DASHBOARD, '/files-manager'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  blank: path(ROOTS_DASHBOARD, '/blank'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    file: path(ROOTS_DASHBOARD, '/file'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title: string) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },
};
export const PATH_ZENDESK = 'https://99minutos.zohodesk.com/portal/es/kb';
export const PATH_ZENDESK_COL = 'https://99minutos.zohodesk.com/portal/es/kb';
export const PATH_TICKET_ZOHODESK_CL =
  'https://99minutos.zohodesk.com/portal/es/newticket?departmentId=820854000000006907';
export const PATH_TICKET_ZOHODESK_LATAM =
  'https://99minutos.zohodesk.com/portal/es/kb/articles/c%C3%B3mo-puedo-contactar-a-un-agente';
export const PATH_ZENDESK_INVOICES = `${PATH_ZENDESK}/categories/7340463560859-Facturación`;
export const PATH_FACEBOOK = 'https://www.facebook.com/99minutos/';
export const PATH_LINKEDIN = 'https://www.linkedin.com/company/99minutos-com/';
export const PATH_YOUTUBE = 'https://www.youtube.com/channel/UCBvysYa3KVY0osl21_HEkag';
export const PATH_TIKTOK = 'https://www.tiktok.com/@99minutos.com';
export const PATH_INSTAGRAM_LATAM = 'https://www.instagram.com/99minutoslatam/';
export const PATH_INSTAGRAM_CHL = 'https://www.instagram.com/99minutoscl/';
export const PATH_SERVICE_STATUS = 'https://status.99minutos.app/';
export const PATH_CLAIMS_PER =
  'https://99minutos.zohodesk.com/portal/es/newticket?departmentId=820854000076985147';
export const PATH_CLAIMS_COL = 'https://mgei8h73g11.typeform.com/to/GZQEAMJV';

export const PATH_DOCS = {
  home: '/',
  support: PATH_ZENDESK,
  support_col: PATH_ZENDESK_COL,
};

export const PATH_SUPPLIER =
  'https://us12.list-manage.com/survey?u=133db14038ac601c8ebbfdfbe&id=2af14391c0';

export const PATH_SEARCH = 'https://tracking.99minutos.com/';

export const PATH_SIGNUP = 'https://envios.99minutos.com/signup';

export const PATH_COVERAGE =
  'https://99downloads.s3.us-west-1.amazonaws.com/Cobertura_99minutos.xlsx';

export const PATH_ENTER = 'https://auth.99minutos.com/?sessionExpired=true';

export const PATH_CREATE_ACCOUNT = 'https://99minutos.com/';

export const PATH_CASH99 =
  'https://mgei8h73g11.typeform.com/to/N4BPSiRf?typeform-source=www.99minutos.com';
export const PATH_CASH99_MX =
  'https://mgei8h73g11.typeform.com/to/Vr0wrWAY?typeform-source=www.99minutos.com';

export const PATH_RUTA99 = 'https://mgei8h73g11.typeform.com/to/nNuCfl9j';

export const PATH_RUTA99_API = 'https://developers.99minutos.com/reference/post_oauth-token';

export const PATH_FULFILL99 = 'https://leads.99minutos.com/lg/';

export const PATH_TAILOR_MADE =
  'https://mgei8h73g11.typeform.com/to/GlDTDyhC?typeform-source=www.99minutos.com';

export const PATH_BUSINESS_ADVISOR = 'https://mgei8h73g11.typeform.com/to/HTD8Wvep';

export const PATH_ENVIOS99 = 'https://leads.99minutos.com/lg/';

export const PATH_PUNTO99 = 'https://network.punto99.com/';

export const PATH_PUNTO99_CONNECT = 'https://developers.99minutos.com/docs/punto99';

export const PATH_CREATE_FIRST_SHIPMENT = 'https://envios.99minutos.com/signup';

export const PATH_PUNTO99_INTEGRATE = `${PATH_ZENDESK}/soporte-mexico`;

export const PATH_SCHEDULE_CALL: Record<CountriesCode, string> = {
  MX: 'https://mgei8h73g11.typeform.com/to/Vr0wrWAY?typeform-source=www.99minutos.com',
  CO: 'https://mgei8h73g11.typeform.com/to/Vr0wrWAY',
  PE: 'https://mgei8h73g11.typeform.com/to/Vr0wrWAY',
  CL: 'https://mgei8h73g11.typeform.com/to/KRZs8iiV',
};

export const PATH_WHATSAPP_CALL = 'https://wa.me/15585515568';

export const PATH_AMVO =
  'https://www.amvo.org.mx/estudios/estudio-sobre-venta-online-en-mexico-2023/';

export const PATH_RUTA = 'https://mgei8h73g11.typeform.com/to/ctTQGZux';

export const PATH_PUNTO99_AFILIAR =
  'https://99minutos7806.zendesk.com/hc/es-419/articles/8012097659803-Me-interesa-afiliar-a-mi-tienda-como-punto99-cu%C3%A1les-son-los-requisitos-y-c%C3%B3mo-puedo-aplicar-';

export const PATH_PUNTO99_DELIVERY_CENTER = {
  AFILIAR: 'https://www.punto99.com/',
  FORM_DELIVERY_CENTER: 'https://mgei8h73g11.typeform.com/to/HDXjpF7M',
};

export const PATH_BASE_DISTRIBUTOR = {
  BE_PART: 'https://forms.clickup.com/30975448/f/xh9er-38074/W7ME5FLKWIJOXKS5GQ',
  WHATSAPP: 'https://wa.me/525568178839',
  CALL_PHONE: 'tel:+525568178839',
  APP_GOOGLE: 'https://play.google.com/store/apps/details?id=com.app99minutos.app99minutos',
};

export const PATH_FREELANCE = {
  WORK_ADAPTS: 'https://forms.clickup.com/30975448/f/xh9er-38014/3VWPRQ870LB0I0NC43',
  WHATSAPP: 'https://wa.me/525510518752',
  CALL_PHONE: 'tel:+525510518752',
  APP_GOOGLE: 'https://play.google.com/store/apps/details?id=com.app99minutos.app99minutos',
};

export const PATH_ALLIES = {
  LOOKING_CANDIDATES: 'https://mgei8h73g11.typeform.com/to/Xu5FBfe7',
};

export const PATH_SERVICE_PARTNER = 'https://mgei8h73g11.typeform.com/to/eGxnTw0y';

export const PATH_TALK_ADVISOR_MAIN =
  'https://mgei8h73g11.typeform.com/to/Vr0wrWAY?typeform-source=www.99minutos.com';

export const PATH_TALK_ADVISOR: PathTalkAdvisor = {
  CL: 'https://mgei8h73g11.typeform.com/leadsCL?utm_medium=webCLrates',
  PE: 'https://mgei8h73g11.typeform.com/leadsPE?utm_medium=webPE',
};

export const PATH_TALK_ADVISOR_SEND: PathTalkAdvisor = {
  CL: 'https://mgei8h73g11.typeform.com/leadsCL?utm_medium=webCLenvios',
  PE: 'https://mgei8h73g11.typeform.com/leadsPE?utm_medium=webPE',
};

export const PATH_TALK_ADVISOR_TAILOR: PathTalkAdvisor = {
  CL: 'https://mgei8h73g11.typeform.com/leadsCL?utm_medium=webCLtailor',
  PE: 'https://mgei8h73g11.typeform.com/leadsPE?utm_medium=webPE',
  MX: 'https://mgei8h73g11.typeform.com/to/Vr0wrWAY?typeform-source=www.99minutos.com',
  CO: 'https://mgei8h73g11.typeform.com/to/GlDTDyhC?typeform-source=www.99minutos.com',
};

export const PATH_TALK_ADVISOR_R99: PathTalkAdvisor = {
  CL: 'https://mgei8h73g11.typeform.com/leadsCL?utm_medium=webCLruta',
  PE: 'https://mgei8h73g11.typeform.com/leadsPE?utm_medium=webPE',
};

export const PATH_TYPEFORM_MX = 'https://mgei8h73g11.typeform.com/mex-leads';
export const PATH_TYPEFORM_CL =
  'https://mgei8h73g11.typeform.com/leadsCL?utm_medium=typeformMX&typeform-source=www.google.com';
export const PATH_TYPEFORM_CO = 'https://mgei8h73g11.typeform.com/colombia-leads';
export const PATH_TYPEFORM_PE = 'https://mgei8h73g11.typeform.com/leadsPE?utm_medium=typeform';

export const PATH_TALK_ADVISOR_CL = 'https://mgei8h73g11.typeform.com/leadsCL?utm_medium=signupCL';
